'use client';
import { useEffect, useState, use } from 'react';
import LoginForm from '@/components/LoginForm';
import LoginResetForm from '@/components/LoginResetForm';

export default function Login(props) {
  const searchParams = use(props.searchParams);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  if (searchParams.code) {
    return (
      ready && (
        <div className="flex flex-col flex-1 p-4 w-full items-center">
          <LoginResetForm params={searchParams} />
        </div>
      )
    );
  } else {
    return (
      ready && (
        <div className="flex flex-col flex-1 p-4 w-full items-center">
          <LoginForm params={searchParams} />
        </div>
      )
    );
  }
}
