'use client';
import { createContext, useContext } from 'react';

export type LanguageContextType = {
  locale: string;
  setLocale: (locale: string) => void;
};

export const LanguageContext = createContext<LanguageContextType>({
  locale: 'en',
  setLocale: () => {},
});

export function useLanguage() {
  return useContext(LanguageContext);
}
