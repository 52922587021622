const dictionary = {
  //home
  ENTI_LOGO_ALT: 'ENTi Logo',
  GO_TO_PANEL: 'Go to panel',
  ACCESS: 'Sign in',
  HERO_TITLE: 'Let your customers be your marketing',
  HERO_SUBTITLE: 'A positive review stays forever, but so does a negative one.',
  AVOID_NOW: 'Avoid it now!',
  STAT_TITLE: '79% of customers want to give their opinion',
  CUSTOMER_OPINION_TITLE:
    'Customers leave reviews in the first space they find to do so',
  CUSTOMER_OPINION_DESC:
    "If your customers want to say something good or bad about your company, and you don't give them a simple way to do it, they'll do it on Google.",
  ONE_STAR_TITLE: 'A 1-star review on Google stays forever',
  ONE_STAR_DESC:
    "Most customer complaints are problems that can be solved, however even when you solve them, they won't delete their bad review from Google.",
  BAD_REVIEWS_TITLE: 'Bad reviews on Google sabotage your SEO',
  BAD_REVIEWS_DESC:
    "No SEO expert can fix the damage of a 2-star reputation, that's why you have to avoid them.",
  RATING_INTERFACE_ALT: 'Rating Interface',
  RATING_INTERFACE_IMG: '/wmr.png',
  ENTI_BENEFIT_TITLE:
    'With ENTi receive only 4 and 5 stars on Google, and the rest internally',
  ENTI_BENEFIT_DESC:
    "In the enti.ee panel you'll see your customers' sentiment and act in time before others have the same problem, improving your reputation.",
  INTEGRATIONS_TITLE: 'Integrations',
  API_TITLE: 'API',
  API_DESC:
    'ENTi has an API for sending custom emails, just like the ones generated from the delivery panel. This allows you to integrate using webhooks from any external CRM or management system.',
  ADVISORY_TITLE: 'Development',
  ADVISORY_DESC:
    'If you need to integrate ENTi with another software, you can hire our development service. If you have an Enterprise plan, you will get a 10% off the development price.',
  TECHNOLOGY_TITLE: 'Technology',
  TECHNOLOGY_DESC:
    'Thanks to our long trajectory creating online services and applications, we developed cutting-edge technology that delivers quality and efficiency.',
  TESTIMONIAL_QUOTE:
    "With ENTi's mailing, we got more than 80 reviews in Google the first week, and since then, at least 5 more every week.",
  TESTIMONIAL_AUTHOR: 'Miguel Fernandez - CEO Nudo Ciego',
  CONTACT_TITLE: 'Contact',
  PRIVACY_POLICY: 'PRIVACY POLICY',
  PRIVACY_POLICY_URL: '/privacy',
  MENU_HOME: 'Home',
  MENU_INTEGRATIONS: 'Integrations',
  MENU_PRICING: 'Pricing',
  MENU_CONTACT: 'Contact',

  //Company settings
  COMPANY_SAVE_OK: 'Company settings saved',
  COMPANY_SAVE_ERROR: 'Error saving company settings',
  LANGUAGE: 'Language',
  LANGUAGE_EN: 'English',
  LANGUAGE_ES: 'Español (Spanish)',
  COMPANY_EDITOR_TITLE: 'Edit Company',
  COMPANY_EDITOR_NEW: 'New Company',
  COMPANY_EDITOR_CUSTOMIZE: 'Customize Company',
  RESET_CUSTOMIZATION: 'Reset Customization',
  QUOTA_TITLE: 'Sending Quota',
  QUOTA_ENABLED: 'Enabled',
  QUOTA_DISABLED: 'Disabled',
  DAILY_LIMIT: 'Daily Limit',
  USED_SENDS: 'Used Sends',
  LAST_RESET: 'Last Reset',
  RESET_DAILY_LIMIT: 'Reset Daily Limit',
  COMPANY_INFO: 'Company Information',
  COMPANY_NAME: 'Name',
  CUSTOM_URL: 'Custom URL',
  REPEATED_SENDS: 'Repeated Deliveries',
  ALLOW_RESEND: 'Allow resending to an address that has already been delivered',
  PLACES_ID: 'Places ID',
  SEARCH: 'Search',
  GOOGLE_URL: 'Google URL',
  OPEN: 'Open',
  INTEGRATIONS_API: 'Integrations (API)',
  API_KEY: 'API Key',
  VIEW_DOCUMENTATION: 'View Documentation',
  REGENERATE_API_KEY: 'Regenerate API Key',
  PRIVATE_RATINGS: 'Private Ratings',
  PRIVATE_RATINGS_HELP:
    'Choose how many stars you want to be private.\n\nIf you choose 3, ratings of 4 or more stars will go to Google.\n\nIf you choose 4, only 5-star ratings will go to Google.',
  WHAT_IS_THIS: 'what is this',
  TEXT_COLOR: 'Text Color',
  BACKGROUND_COLOR: 'Background Color',
  TITLE: 'Title',
  CUSTOM_TITLE: 'Custom Title (%n = name)',
  PAGE_WELCOME: 'Page Welcome',
  PAGE_MESSAGE: 'Page Message',
  FEEDBACK_MESSAGE: 'Message to show after sending feedback',
  FEEDBACK_EMAIL: 'Feedback recipient email',
  LOGO_PREVIEW: 'Logo Preview',
  UPLOAD_NEW_LOGO: 'Upload new logo',
  LOGO_UPLOAD_TEXT: 'Click or drag an image to this area to upload',
  LOGO_UPLOAD_HINT: 'Maximum size: 2MB. Allowed formats: JPG, PNG',
  LOGO_MAX_WIDTH: 'Maximum logo width',
  LOGO_BOTTOM_MARGIN: 'Logo bottom margin',
  SAVE_CHANGES: 'Save Changes',
  CHANGES_SAVED: 'Changes saved',
  CREATE_COMPANY: 'Create Company',
  VIEW_AS_USER: 'View as user',
  ARE_YOU_SURE: 'Are you sure?',
  CUSTOMIZATION_WARNING:
    'This will reset all the customization for this company.',
  YES_RESET: 'Yes, reset',
  CANCEL: 'Cancel',
  API_DOCUMENTATION: 'API Documentation',
  HOW_TO_USE: 'How to use',
  JSON_EXAMPLE: 'JSON example',
  CURL_EXAMPLE: 'cURL example',
  REQUIRED_PARAMS: 'Required params:',
  YOUR_API_KEY: 'Your API key',
  PERSON_NAME: "Recipient's name",
  PERSON_EMAIL: "Recipient's email",
  POST_REQUEST_INSTRUCTION: 'Make a POST request to:',
  CLOSE: 'Close',
  REGENERATE_KEY_WARNING:
    'If you regenerate the API Key, the previous one will stop working. \n\nYou must SAVE your changes for this to have effect.',

  TITLE_COMPANIES: 'Companies',
  TITLE_USERS: 'Users',
  TITLE_DELIVERIES: 'Deliveries',
  TITLE_FEEDBACK: 'Feedback',
  TITLE_SETTINGS: 'Settings',
  TITLE_INTEGRATIONS: 'Integrations',
  LOG_OUT: 'Sign out',

  // Deliveries component
  DELIVERIES_TITLE: 'Deliveries',
  SINGLE_DELIVERY: 'Send to one person',
  BULK_DELIVERY: 'Bulk delivery',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  NAME: 'Name',
  EMAIL: 'Email',
  SEND: 'Send',
  DAILY_LIMIT_REACHED: 'You have reached your daily limit.',
  ATTACH_CSV: 'Attach a CSV file with the format "name,lastname,email"',
  DOWNLOAD_TEMPLATE: 'Download Template',
  DOWNLOAD: 'Download',
  GENERIC_LINK: 'Generic Link',
  CUSTOMIZE_URL: 'Customize URL',
  SHARE_LINK_DESC:
    'You can share this link via WhatsApp, Email, or put it on your page.',
  COPY_TO_CLIPBOARD: 'Copy to clipboard',
  SHARE_ON_WHATSAPP: 'Share on WhatsApp',
  LINK_COPIED: 'Link copied to clipboard',
  GENERIC_QR: 'Generic QR',
  QR_DESC:
    'You can download this QR to print it and place it in your location. The SVG format is vector-based and can be scaled without losing quality.',
  CLIENTS: 'Clients',
  CLIENTS_DESC:
    "List of clients and their delivery history. Click on a client's name to see their delivery details.",
  MESSAGE_SENT: 'Message sent',
  ERROR_OCCURRED: 'Error: ',
  DAILY_LIMIT_REACHED_ERROR: 'Daily limit reached',
  CLIENT_ERROR: 'Could not create or find client',
  RESEND_TUTORIAL: 'Here you can modify the delivery behavior',
  URL_TUTORIAL: 'Here you can customize your URL',
  UNDERSTOOD: 'Understood',

  // Queue Status
  VIEW_DELIVERY_PROGRESS: 'View delivery progress',
  DELIVERY_QUEUE_STATUS: 'Delivery Queue Status',
  QUEUE_INFO_1:
    'Deliveries are being processed automatically in the background.',
  QUEUE_INFO_2: 'You can close this window and the process will continue.',

  // Feedback Page
  DOWNLOAD_CSV: 'Dowlnoad CSV',
  REMOVE: 'Delete',
  REMOVE_CONFIRM: 'Are you sure to delete this feedback?',
  REMOVE_WARNING: 'This action cannot be undone',
  YES_REMOVE: 'Yes, delete',
  VIEW_GOOGLE: 'View rating in <b>Google</b>',

  // WhatsApp Dialog
  SHARE_VIA_WHATSAPP: 'Share via WhatsApp',
  USE_VARIABLES: 'Use these variables:',
  WRITE_MESSAGE: 'Write your message:',
  PREVIEW: 'Preview:',
  LINK_VARIABLE_REQUIRED: 'Your message must include the link variable ( %e )',
  SHARE: 'Share',
  DEFAULT_WSP_MSG:
    'Hi, I would really appreciate it if you could leave us your opinion about %c at this link: %e ✨',

  // CSV Uploader
  UPLOAD_CSV: 'Upload CSV',
  PROCESSING_CSV: 'Processing CSV',
  DELIVERY_STATUS: 'Delivery Status',
  PROCESSING_FILE: 'Processing CSV file...',
  CSV_PROCESSED_A: 'CSV processed.',
  CSV_PROCESSED_B: 'records queued for delivery.',
  CSV_ERROR: 'Error processing CSV: ',

  // Client List
  DELIVERY_HISTORY: 'Delivery History',
  REGISTRATION_DATE: 'Date',
  LAST_DELIVERY: 'Last Delivery',
  STATUS: 'Status',
  CONTEXT: 'Context',
  IN_QUEUE: 'In Queue',
  SENT: 'Sent',
  OPENED: 'Opened',
  CLICKED: 'Clicked',
  RATED: 'Rated',
  ERROR: 'Error',
  NO_DELIVERIES: 'No deliveries',

  //General
  ENABLED_MALE: 'Enabled',
  ENABLED_FEMALE: 'Enabled',
  DISABLED_MALE: 'Disabled',
  DISABLED_FEMALE: 'Disabled',
  CONNECT: 'Connect',
  GET: 'Get',
  ADMIN_MALE: 'Administrator',
  ADMIN_FEMALE: 'Administrator',
  VIEW: 'View',
  INVALID_API_KEY: 'Invalid API Key',
  NO_ADMIN_FOUND: 'No admin found',
  QR_INSTRUCTIONS_1: 'Scan with your phone to try it out,',
  QR_INSTRUCTIONS_2: 'then download it and use it anywhere!',

  //Mailing
  RATING_SUBJECT_RATE: 'Rate your experience!',
  RATING_SUBJECT_FEEDBACK: 'New feedback for',
  RATING_TITLE: 'Hello!',
  RATING_TITLE_CUSTOM: 'Hi, %n!',
  RATING_WELCOME: 'How would you rate our service?',
  RATING_INSTRUCTIONS: 'Please tell us how to improve!',
  RATING_FEEDBACK: 'Thank you!',
  ANONYMOUS: 'Anonymous visitor',

  //Form
  FORM_FIELD_NAME: 'Name',
  FORM_FIELD_EMAIL: 'Email',
  FORM_BUTTON_SEND: 'Send',

  //Links
  LINK_EMPRESAS: '/companies',
  LINK_USUARIOS: '/users',
  LINK_ENVIOS: '/deliveries',
  LINK_OPINIONES: '/feedback',
  LINK_AJUSTES: '/settings',
  LINK_INTEGRACIONES: '/integrations',

  //Integrations
  INTEGRATION_INFO: 'Integration details',
  INTEGRATION_REMOVE: 'Remove integration',
  INTEGRATION_REMOVE_CONFIRM:
    'Are you sure to remove this integration?\n\nThis will remove the webhook within PipeDrive',
  INTEGRATION_REMOVE_OK: 'Integration removed successfully',
  INTEGRATION_REMOVE_ERROR: 'Error removing integration',
  INTEGRATION_ENABLE_OK: 'Integration enabled successfully',
  INTEGRATION_ENABLE_ERROR: 'Error enabling integration',
  INTEGRATION_ENABLED: 'Integration enabled',
  INTEGRATION_DISABLED: 'Integration disabled',

  //PipeDrive Integration
  PIPEDRIVE_WEBHOOK_REMOVE_ERROR: 'Error deleting webhook from PipeDrive',
  PIPEDRIVE_STAGES_TITLE: 'Auto-mailing pipeline stages',
  PIPEDRIVE_STAGES_RELOAD: 'Reload stages',
  PIPEDRIVE_RESEND_ENABLED_INFO:
    'Customers who have already received an email before will receive it again if they enter an active stage in this integration.',
  PIPEDRIVE_RESEND_DISABLED_INFO:
    'Customers who have already received an email before will NOT receive it again if they enter an active stage in this integration.',
  PIPEDRIVE_YOU_CAN: 'You can ',
  PIPEDRIVE_CHANGE_BEHAVIOR: 'change this behavior',
  PIPEDRIVE_SKIP: 'Skip mailing if client has already rated',
  PIPEDRIVE_STAGES_OK: 'Stages succesfully reloaded',
  PIPEDRIVE_STAGES_SAVE_ERROR: 'Error saving stages',

  //Login & registration
  LOGIN_SIGN_IN: 'Sign in',
  LOGIN_SIGN_IN_2: 'Sign In',
  LOGIN_PASSWORD: 'Password',
  LOGIN_INVALID: 'Invalid email or password.',
  LOGIN_FORGOT: 'Forgot your password?',
  LOGIN_FORGOT_MODAL_INSTRUCTIONS:
    'To begin the recovery process, enter your email address:',
  LOGIN_FORGOT_MODAL_FEEDBACK:
    'If that address belongs to an existing account, you will receive instructions to proceed.',
  LOGIN_RESET_TITLE: 'Change Password',
  LOGIN_RESET_NEW_PASSWORD: 'New Password',
  LOGIN_RESET_BUTTON: 'Change',
  LOGIN_RESET_PASSWORD_PLACEHOLDER: '••••••••',
  LOGIN_RESET_PASSWORD_WEAK: 'Password must be at least 8 characters long',
  SIGN_UP_NOW: 'Sign up now',
  ACCOUNT_BENEFITS:
    'Join ENTi and start improving your online reputation today.',
  SELECT_LOGIN_SIGNUP: 'Select to login or sign up',

  // SignupBackend translations
  NO_SOUL: 'Please try again',
  COUPON_EMPTY: 'Empty code',
  APPSUMO_CODE_UNAVAILABLE: 'AppSumo code not found or no longer available',
  COUPON_UNAVAILABLE: 'Coupon not found or no longer available',
  COUPON_VERIFICATION_ERROR: 'Error verifying coupon',
  INVALID_COUPON_NUMBER: 'Invalid number of coupons',
  APPSUMO_METADATA_ERROR: 'Error obtaining metadata',

  // Signup page
  CREATE_ACCOUNT_TITLE: 'Create your ENTi account',
  CREATE_ACCOUNT_SUBTITLE:
    'Join ENTi and start improving your online reputation today.',
  PHONE: 'Phone',
  PHONE_PLACEHOLDER: '+1 555 123 4567',
  COMPANY: 'Company',
  EMAIL_PLACEHOLDER: 'youremail@example.com',
  PASSWORD: 'Password',
  ENTER_FIRST_NAME: 'Enter your first name',
  ENTER_LAST_NAME: 'Enter your last name',
  ENTER_COMPANY_NAME: 'Enter your company name',
  ENTER_PHONE: 'Enter your phone number',
  ENTER_EMAIL: 'Enter your email',
  ENTER_VALID_EMAIL: 'Enter a valid email',
  ENTER_PASSWORD: 'Enter a password',
  PASSWORD_MIN_LENGTH: 'Password must be at least 8 characters long',
  CHOOSE_PLAN_OR_COUPON: 'Choose your plan or enter a coupon',
  CANNOT_SELECT_PLAN_WITH_COUPONS:
    'You cannot select a plan if you have coupons applied',
  SELECT_PLAN: 'Select Plan',
  VIEW_PLANS: 'View Plans',
  REDEEM_CODE: 'Redeem Code',
  USE_COUPONS: 'Use Coupons',
  ENTER_CODES_HERE: 'Enter codes or coupons here',
  ENTER_COUPON_CODE_PLACEHOLDER: 'Enter your coupon code',
  ENTER_COUPON_CODE: 'Enter a coupon code',
  ADD: 'Add',
  COUPON_ALREADY_ADDED: 'This coupon has already been added',
  COUPON_ADDED_SUCCESS: 'Coupon added successfully',
  COUPON_VERIFICATION_ERROR: 'Error verifying coupon',
  MULTIPLE_COUPONS_APPLIED: '%d coupons applied',
  ONE_COUPON_APPLIED: '1 coupon applied',
  INCOMPATIBLE_COUPON_ERROR:
    'The coupon you added cannot be combined with others',
  MAX_APPSUMO_REACHED: 'You have reached the limit of %d AppSumo coupons',
  MAX_CAMPAIGN_REACHED: 'You have reached the limit of %d coupons for %s',
  MAX_APPSUMO_COUPONS_ERROR:
    'You have already added the maximum allowed AppSumo coupons (%d).',
  MAX_CAMPAIGN_COUPONS_ERROR:
    'You have already added the maximum number of coupons (%d) for the "%s" campaign.',
  EXISTING_NO_COMPATIBILITY_ERROR:
    'The coupon you already added cannot be combined with other coupons.',
  NEW_NO_COMPATIBILITY_ERROR: 'This coupon cannot be combined with others.',
  DIFFERENT_CAMPAIGN_ERROR:
    'This coupon belongs to a different campaign and cannot be combined.',
  CONFIRM_REMOVAL: 'Confirm removal',
  REMOVE_COUPON_CONFIRM: 'Are you sure you want to remove this coupon?',
  YES: 'Yes',
  NO: 'No',
  THIS_CAMPAIGN: 'this campaign',
  MESSAGES: 'messages',
  WITH_QUOTA: 'with quota',
  DAILY: 'daily',
  PROMOTION: 'Promotion',
  WITH_INTEGRATIONS: 'With integrations',
  UNLIMITED_USERS: 'Unlimited users',
  NO_ADDITIONAL_USERS: 'No additional users',
  ADDITIONAL_USER: 'additional user',
  ADDITIONAL_USERS: 'additional users',
  FREE: 'Free',
  EVERY: 'every',
  MONTHS: 'months',
  YEARS: 'years',
  PLUS_TAXES: '+ taxes',
  PROMO_CODE_APPLIED: 'Promotional code applied',
  HAVE_COUPON_QUESTION: 'Do you have a coupon or discount code?',
  NO_COUPONS_MESSAGE:
    "If you don't have coupons or codes, you can select a plan",
  APPSUMO_BENEFITS: 'AppSumo Benefits',
  REMOVE_APPSUMO_COUPONS_CONFIRM:
    'Are you sure you want to remove all AppSumo coupons?',
  COUPON: 'coupon',
  COUPONS: 'coupons',
  APPLIED: 'applied',
  APPLIED_PLURAL: 'applied',
  APPSUMO_LIFETIME_DEAL:
    'AppSumo Lifetime Deal with daily messages. Lifetime access with a single payment.',
  DAILY_QUOTA: 'Daily sending quota:',
  ACCESS_TO_INTEGRATIONS: 'Access to integrations with other platforms',
  UNLIMITED_USERS_FOR_COMPANY: 'Unlimited users for your company',
  ADDITIONAL_USERS_COUNT: '%d additional user%s',
  S: 's',
  MAX_COUPONS: 'Max. stackable coupons:',
  LIFETIME_PLAN: 'Lifetime plan - one payment, forever!',
  TERMS_AGREEMENT: 'By creating an account, you are accepting the',
  TERMS_AND_CONDITIONS: 'terms and conditions',
  AND: 'and',
  CREATE_ACCOUNT: 'Create Account',
  ALREADY_HAVE_ACCOUNT: 'Already have an account?',
  INCOMPATIBLE_COUPONS: 'Incompatible Coupons',
  CLEAR_EXISTING_COUPONS: 'Clear existing coupons',
  CLEAR_COUPONS_FIRST:
    'If you want to use it, you must remove the others first.',
  SIGNUP_SUCCESS: 'Registration successful!',
  SIGNUP_ERROR: 'Registration error: ',

  //Pricing
  PRICING_TITLE: 'Plans & Pricing',
  PRICING_SUBTITLE: 'Choose the perfect plan for your needs',
  PRICING_DEVELOPMENT:
    'If you need to integrate ENTi with another software, you can hire our development service.',
  MOST_POPULAR: 'Most Popular',
  START_NOW: 'Start Now',
  YEAR: 'year',
  MONTH: 'month',
  MONTHLY: 'Monthly',
  ANNUAL: 'Annual',
  TWO_MONTHS_FREE: '2 months free!',

  BASIC_PLAN: 'Basic Plan',
  BASIC_FEATURE_1: '1 user',
  BASIC_FEATURE_2: '10 daily messages',
  BASIC_FEATURE_3: 'Real-time tracking',

  PRO_PLAN: 'Professional Plan',
  PRO_FEATURE_1: '3 users',
  PRO_FEATURE_2: '50 daily messages',
  PRO_FEATURE_3: 'Real-time tracking',
  PRO_FEATURE_4: 'Delivery API',
  PRO_FEATURE_5: 'Integrations',
  PRO_FEATURE_6: 'Email Support',

  ENTERPRISE_PLAN: 'Enterprise Plan',
  ENTERPRISE_FEATURE_1: 'Unlimited users',
  ENTERPRISE_FEATURE_2: '300 daily messages',
  ENTERPRISE_FEATURE_3: 'Real-time tracking',
  ENTERPRISE_FEATURE_4: 'Delivery API',
  ENTERPRISE_FEATURE_5: 'Integrations',
  ENTERPRISE_FEATURE_6: 'Email Support',
  ENTERPRISE_FEATURE_7: '10% discount on integration development',
};

export default dictionary;
