const dictionary = {
  ENTI_LOGO_ALT: 'ENTi Logo',
  GO_TO_PANEL: 'Ir al panel',
  ACCESS: 'Acceder',
  HERO_TITLE: 'Que tus clientes sean tu marketing',
  HERO_SUBTITLE:
    'Una opinión positiva queda para siempre, pero una negativa también.',
  AVOID_NOW: '¡Evitalo ahora!',
  STAT_TITLE: '79% de los clientes quieren opinar',
  CUSTOMER_OPINION_TITLE:
    'Los clientes opinan en el primer espacio que encuentran para hacerlo',
  CUSTOMER_OPINION_DESC:
    'Si tus clientes quieren decir algo bueno o malo de tu empresa, y no les das una forma simple de hacerlo, lo harán en Google.',
  ONE_STAR_TITLE: 'Una opinión de 1 estrella en Google queda para siempre',
  ONE_STAR_DESC:
    'La mayoría de las quejas de los clientes son problemas que se pueden solucionar, sin embargo aún cuando los solucionas, ellos no borrarán su mala opinión de Google.',
  BAD_REVIEWS_TITLE: 'Malas opiniones en Google sabotean tu SEO',
  BAD_REVIEWS_DESC:
    'Ningún experto en SEO podrá solucionar el daño de una reputación de 2 estrellas, por eso tienes que evitarlas.',
  RATING_INTERFACE_ALT: 'Rating Interface',
  RATING_INTERFACE_IMG: '/pnt.png',
  ENTI_BENEFIT_TITLE:
    'Con ENTi recibe sólo 4 y 5 estrellas en Google, y las demás por interno',
  ENTI_BENEFIT_DESC:
    'En el panel de enti.ee verás el sentimiento de tus clientes y actuarás a tiempo antes que otros tengan el mismo problema, mejorando tu reputación.',
  INTEGRATIONS_TITLE: 'Integraciones',
  API_TITLE: 'API',
  API_DESC:
    'ENTi cuenta con una API para realizar envíos personalizados de correos, equivalentes a los que puedes generar desde el panel de envíos. Esto te permite integrarte usando los webhooks de cualquier CRM o sistema de gestión externo.',
  ADVISORY_TITLE: 'Desarrollo',
  ADVISORY_DESC:
    'Si necesitas integrar ENTi con otro software, puedes contratar nuestro servicio de desarrollo. Si contratas un plan Empresarial recibes un 10% de dcto. en el valor del desarrollo.',
  TECHNOLOGY_TITLE: 'Tecnología',
  TECHNOLOGY_DESC:
    'Gracias a nuestra larga trayectoria creando servicios y aplicaciones online, desarrollamos una tecnología de punta que entrega calidad y eficiencia al flujo de ENTi.',
  TESTIMONIAL_QUOTE:
    'Con el mailing de ENTi, conseguimos más de 80 opiniones en Google la primera semana, y desde entonces al menos 5 nuevas semanales.',
  TESTIMONIAL_AUTHOR: 'Miguel Fernandez - CEO Nudo Ciego',
  CONTACT_TITLE: 'Contacto',
  PRIVACY_POLICY: 'POLÍTICA DE PRIVACIDAD',
  PRIVACY_POLICY_URL: '/privacidad',
  MENU_HOME: 'Inicio',
  MENU_INTEGRATIONS: 'Integraciones',
  MENU_PRICING: 'Precios',
  MENU_CONTACT: 'Contacto',

  //Company settings
  COMPANY_SAVE_OK: 'Empresa guardada exitosamente',
  COMPANY_SAVE_ERROR: 'Error al guardar la empresa',
  LANGUAGE: 'Idioma',
  LANGUAGE_EN: 'English (Inglés)',
  LANGUAGE_ES: 'Español',
  COMPANY_EDITOR_TITLE: 'Editar empresa',
  COMPANY_EDITOR_NEW: 'Nueva Empresa',
  COMPANY_EDITOR_CUSTOMIZE: 'Personalizar Empresa',
  RESET_CUSTOMIZATION: 'Reestablecer personalización',
  QUOTA_TITLE: 'Cuota de Envíos',
  QUOTA_ENABLED: 'Habilitado',
  QUOTA_DISABLED: 'Deshabilitado',
  DAILY_LIMIT: 'Límite diario',
  USED_SENDS: 'Envíos usados',
  LAST_RESET: 'Último reinicio',
  RESET_DAILY_LIMIT: 'Reiniciar límite diario',
  COMPANY_INFO: 'Información de la Empresa',
  COMPANY_NAME: 'Nombre',
  CUSTOM_URL: 'URL Personalizada',
  REPEATED_SENDS: 'Envíos repetidos',
  ALLOW_RESEND: 'Permitir volver a enviar hacia un correo que ya se ha enviado',
  PLACES_ID: 'Places ID',
  SEARCH: 'Buscar',
  GOOGLE_URL: 'URL de Google',
  OPEN: 'Abrir',
  INTEGRATIONS_API: 'Integraciones (API)',
  API_KEY: 'API Key',
  VIEW_DOCUMENTATION: 'Ver Documentación',
  REGENERATE_API_KEY: 'Regenerar API Key',
  PRIVATE_RATINGS: 'Valoraciones privadas',
  PRIVATE_RATINGS_HELP:
    'Elige qué cantidad de estrellas quieres que sean privadas.\n\nSi eliges 3, las valoraciones de 4 o más estrellas irán a Google.\n\nSi eliges 4, solo las de 5 irán a Google.',
  WHAT_IS_THIS: 'qué es esto',
  TEXT_COLOR: 'Color de Texto',
  BACKGROUND_COLOR: 'Color de Fondo',
  TITLE: 'Título',
  CUSTOM_TITLE: 'Título personalizado (%n = nombre)',
  PAGE_WELCOME: 'Bienvenida en la página',
  PAGE_MESSAGE: 'Mensaje en la página',
  FEEDBACK_MESSAGE: 'Mensaje a mostrar después de enviar feedback',
  FEEDBACK_EMAIL: 'Email receptor del feedback',
  LOGO_PREVIEW: 'Vista previa logo',
  UPLOAD_NEW_LOGO: 'Subir nuevo logo',
  LOGO_UPLOAD_TEXT: 'Haga clic o arrastre una imagen a esta área para cargarla',
  LOGO_UPLOAD_HINT: 'Tamaño máximo: 2MB. Formatos permitidos: JPG, PNG',
  LOGO_MAX_WIDTH: 'Ancho máximo del logo',
  LOGO_BOTTOM_MARGIN: 'Margen inferior del logo',
  SAVE_CHANGES: 'Guardar cambios',
  CHANGES_SAVED: 'Cambios guardados',
  CREATE_COMPANY: 'Crear Empresa',
  VIEW_AS_USER: 'Ver como usuario',
  ARE_YOU_SURE: '¿Estás seguro?',
  CUSTOMIZATION_WARNING: 'Esto restablecerá la personalización de la empresa.',
  YES_RESET: 'Sí, restablecer',
  CANCEL: 'Cancelar',
  API_DOCUMENTATION: 'Documentación API',
  HOW_TO_USE: 'Cómo usar',
  JSON_EXAMPLE: 'Ejemplo JSON',
  CURL_EXAMPLE: 'Ejemplo de cURL',
  REQUIRED_PARAMS: 'Parámetros requeridos:',
  YOUR_API_KEY: 'Tu clave de API',
  PERSON_NAME: 'Nombre de la persona',
  PERSON_EMAIL: 'Correo electrónico',
  POST_REQUEST_INSTRUCTION: 'Realiza una petición POST a:',
  CLOSE: 'Cerrar',
  REGENERATE_KEY_WARNING:
    'Si cambias la API Key, la anterior dejará de funcionar. \n\nDebes GUARDAR los cambios para que tenga efecto.',

  TITLE_COMPANIES: 'Empresas',
  TITLE_USERS: 'Usuarios',
  TITLE_DELIVERIES: 'Envíos',
  TITLE_FEEDBACK: 'Opiniones',
  TITLE_SETTINGS: 'Ajustes',
  TITLE_INTEGRATIONS: 'Integraciones',
  LOG_OUT: 'Cerrar sesión',

  // Envios component
  DELIVERIES_TITLE: 'Envíos',
  SINGLE_DELIVERY: 'Enviar a una persona',
  BULK_DELIVERY: 'Envío masivo',
  FIRST_NAME: 'Nombre',
  LAST_NAME: 'Apellido',
  NAME: 'Nombre',
  EMAIL: 'Email',
  SEND: 'Enviar',
  DAILY_LIMIT_REACHED: 'Has alcanzado tu límite diario.',
  ATTACH_CSV: 'Adjuntar un archivo CSV con el formato "nombre,apellido,email"',
  DOWNLOAD_TEMPLATE: 'Descargar Plantilla',
  DOWNLOAD: 'Descargar',
  GENERIC_LINK: 'Link genérico',
  CUSTOMIZE_URL: 'Personalizar URL',
  SHARE_LINK_DESC:
    'Puedes compartir este link por WhatsApp, Email, o ponerlo en tu página.',
  COPY_TO_CLIPBOARD: 'Copiar al portapapeles',
  SHARE_ON_WHATSAPP: 'Compartir por WhatsApp',
  LINK_COPIED: 'Link copiado al portapapeles',
  GENERIC_QR: 'QR Genérico',
  QR_DESC:
    'Puedes descargar este QR para imprimirlo y ponerlo en tu local. El formato SVG es vectorial y se puede escalar sin perder calidad.',
  CLIENTS: 'Clientes',
  CLIENTS_DESC:
    'Lista de clientes y su historial de envíos. Haz clic en el nombre de un cliente para ver el detalle de sus envíos.',
  MESSAGE_SENT: 'Mensaje enviado',
  ERROR_OCCURRED: 'Error: ',
  DAILY_LIMIT_REACHED_ERROR: 'Limite diario alcanzado',
  CLIENT_ERROR: 'No se pudo crear o encontrar el cliente',
  RESEND_TUTORIAL: 'Aquí puedes cambiar el comportamiento de los envíos',
  URL_TUTORIAL: 'Aquí puedes personalizar tu URL',
  UNDERSTOOD: 'Entendido',

  // Queue Status
  VIEW_DELIVERY_PROGRESS: 'Ver progreso de envíos',
  DELIVERY_QUEUE_STATUS: 'Estado de la cola de envíos',
  QUEUE_INFO_1:
    'Los envíos se están procesando automáticamente en segundo plano.',
  QUEUE_INFO_2: 'Puedes cerrar esta ventana y el proceso continuará.',

  // Feedback Page
  DOWNLOAD_CSV: 'Descargar CSV',
  REMOVE: 'Eliminar',
  REMOVE_CONFIRM: '¿Estás seguro de eliminar esta opinión?',
  REMOVE_WARNING: 'Esta acción no se puede deshacer',
  YES_REMOVE: 'Sí, eliminar',
  VIEW_GOOGLE: 'Ver opinión en <b>Google</b>',

  // WhatsApp Dialog
  SHARE_VIA_WHATSAPP: 'Compartir por WhatsApp',
  USE_VARIABLES: 'Utiliza estas variables:',
  WRITE_MESSAGE: 'Redacta tu mensaje:',
  PREVIEW: 'Vista previa:',
  LINK_VARIABLE_REQUIRED:
    'Tu mensaje debe incluir la variable del enlace ( %e )',
  SHARE: 'Compartir',
  DEFAULT_WSP_MSG:
    'Hola, te agradeceria mucho si pudieras dejarnos tu opinión sobre %c en este enlace: %e ✨',

  // CSV Uploader
  UPLOAD_CSV: 'Subir CSV',
  PROCESSING_CSV: 'Procesando CSV',
  DELIVERY_STATUS: 'Estado de envíos',
  PROCESSING_FILE: 'Procesando archivo CSV...',
  CSV_PROCESSED_A: 'CSV procesado.',
  CSV_PROCESSED_B: 'registros en cola para envío.',
  CSV_ERROR: 'Error al procesar el CSV: ',

  // Client List
  DELIVERY_HISTORY: 'Historial de envíos',
  REGISTRATION_DATE: 'Fecha',
  LAST_DELIVERY: 'Último envío',
  STATUS: 'Estado',
  CONTEXT: 'Contexto',
  IN_QUEUE: 'En cola',
  SENT: 'Enviado',
  OPENED: 'Abierto',
  CLICKED: 'Clickeado',
  RATED: 'Valorado',
  ERROR: 'Error',
  NO_DELIVERIES: 'Sin envíos',

  //General
  ENABLED_MALE: 'Habilitado',
  ENABLED_FEMALE: 'Habilitada',
  DISABLED_MALE: 'Deshabilitado',
  DISABLED_FEMALE: 'Deshabilitada',
  CONNECT: 'Conectar',
  GET: 'Obtener',
  ADMIN_MALE: 'Administrador',
  ADMIN_FEMALE: 'Administradora',
  VIEW: 'Ver',
  INVALID_API_KEY: 'API Key inválida',
  NO_ADMIN_FOUND: 'No se encontró un administrador',
  QR_INSTRUCTIONS_1: 'Escanea con tu celular para probar,',
  QR_INSTRUCTIONS_2: 'luego puedes descargarlo y usarlo donde quieras',

  //Mailing
  RATING_SUBJECT_RATE: '¡Valora tu experiencia!',
  RATING_SUBJECT_FEEDBACK: 'Opinión de',
  RATING_TITLE: '¡Hola!',
  RATING_TITLE_CUSTOM: '¡Hola, %n!',
  RATING_WELCOME: '¿Cómo calificas nuestro servicio?',
  RATING_INSTRUCTIONS: '¡Por favor dinos como podríamos mejorar!',
  RATING_FEEDBACK: '¡Gracias!',
  ANONYMOUS: 'Visitante anónimo',

  //Form
  FORM_FIELD_NAME: 'Nombre',
  FORM_FIELD_EMAIL: 'Email',
  FORM_BUTTON_SEND: 'Enviar',

  //Links
  LINK_EMPRESAS: '/empresas',
  LINK_USUARIOS: '/usuarios',
  LINK_ENVIOS: '/envios',
  LINK_OPINIONES: '/opiniones',
  LINK_AJUSTES: '/ajustes',
  LINK_INTEGRACIONES: '/integraciones',

  //Integrations
  INTEGRATION_INFO: 'Información de la integración',
  INTEGRATION_REMOVE: 'Eliminar integración',
  INTEGRATION_REMOVE_CONFIRM:
    '¿Realmente quieres eliminar la integración?\n\nEsto quitará el webhook dentro de PipeDrive',
  INTEGRATION_REMOVE_OK: 'Integración eliminada correctamente',
  INTEGRATION_REMOVE_ERROR: 'Error eliminando integración',
  INTEGRATION_ENABLE_OK: 'Integración activada exitosamente',
  INTEGRATION_ENABLE_ERROR: 'Error activando integración',
  INTEGRATION_ENABLED: 'Integration habilitada',
  INTEGRATION_DISABLED: 'Integration deshabilitada',

  //PipeDrive Integration
  PIPEDRIVE_WEBHOOK_REMOVE_ERROR: 'Error al eliminar webhook de PipeDrive',
  PIPEDRIVE_STAGES_TITLE: 'Etapas en las cuales realizar envíos',
  PIPEDRIVE_STAGES_RELOAD: 'Recargar etapas',
  PIPEDRIVE_RESEND_ENABLED_INFO:
    'Los clientes que ya hayan recibido un correo antes, volverán a recibirlo si entran a una etapa activa en esta integración.',
  PIPEDRIVE_RESEND_DISABLED_INFO:
    'Los clientes que ya hayan recibido un correo antes, NO volverán a recibirlo si entran a una etapa activa en esta integración.',
  PIPEDRIVE_RESEND_CHANGE_INFO: 'Recargar etapas',
  PIPEDRIVE_YOU_CAN: 'Puedes ',
  PIPEDRIVE_CHANGE_BEHAVIOR: 'cambiar este comportamiento',
  PIPEDRIVE_SKIP: 'Omitir envío si la persona ya ha valorado',
  PIPEDRIVE_STAGES_OK: 'Etapas actualizadas exitosamente',
  PIPEDRIVE_STAGES_SAVE_ERROR: 'Error al actualizar etapas',

  //Login & registration
  LOGIN_SIGN_IN: 'Iniciar sesión',
  LOGIN_SIGN_IN_2: 'Iniciar Sesión',
  LOGIN_PASSWORD: 'Contraseña',
  LOGIN_INVALID: 'Usuario o contraseña inválidos.',
  LOGIN_FORGOT: '¿Olvidaste tu contraseña?',
  LOGIN_FORGOT_MODAL_INSTRUCTIONS:
    'Para recuperar tu clave, ingresa el correo de tu cuenta:',
  LOGIN_FORGOT_MODAL_FEEDBACK:
    'Si el correo esta registrado en ENTi, te llegarán instrucciones para reestablecer tu clave.',
  LOGIN_RESET_TITLE: 'Cambiar contraseña',
  LOGIN_RESET_NEW_PASSWORD: 'Nueva contraseña',
  LOGIN_RESET_BUTTON: 'Cambiar',
  LOGIN_RESET_PASSWORD_PLACEHOLDER: '••••••••',
  LOGIN_RESET_PASSWORD_WEAK: 'La contraseña debe tener al menos 8 caracteres',
  SIGN_UP_NOW: 'Crear cuenta',
  ACCOUNT_BENEFITS:
    'Crea una cuenta para acceder a todas las funciones de ENTi.',
  SELECT_LOGIN_SIGNUP: 'Selecciona "Iniciar sesión" o "Registrarse" arriba',

  // SignupBackend
  NO_SOUL: 'Por favor intenta nuevamente',
  COUPON_EMPTY: 'Código vacío',
  REMOVE_APPSUMO_COUPONS_CONFIRM:
    '¿Estás seguro de que deseas eliminar los cupones AppSumo?',
  APPSUMO_CODE_UNAVAILABLE:
    'Código AppSumo no encontrado o ya no está disponible',
  COUPON_UNAVAILABLE: 'Cupón no encontrado o ya no está disponible',
  COUPON_VERIFICATION_ERROR: 'Error al verificar el cupón',
  INVALID_COUPON_NUMBER: 'Número de cupones inválido',
  APPSUMO_METADATA_ERROR: 'Error al obtener metadata',

  // Signup page
  CREATE_ACCOUNT_TITLE: 'Crea tu cuenta en ENTi',
  CREATE_ACCOUNT_SUBTITLE:
    'Únete a ENTi y comienza a mejorar tu reputación online hoy mismo.',
  PHONE: 'Teléfono',
  PHONE_PLACEHOLDER: '+56 9 1234 5678',
  COMPANY: 'Empresa',
  EMAIL_PLACEHOLDER: 'tucorreo@email.com',
  PASSWORD: 'Contraseña',
  ENTER_FIRST_NAME: 'Ingresa tu nombre',
  ENTER_LAST_NAME: 'Ingresa tu apellido',
  ENTER_COMPANY_NAME: 'Ingresa el nombre de tu empresa',
  ENTER_PHONE: 'Ingresa tu teléfono',
  ENTER_EMAIL: 'Ingresa tu email',
  ENTER_VALID_EMAIL: 'Ingresa un email válido',
  ENTER_PASSWORD: 'Ingresa una contraseña',
  PASSWORD_MIN_LENGTH: 'La contraseña debe tener al menos 8 caracteres',
  CHOOSE_PLAN_OR_COUPON: 'Elige tu plan o ingresa un cupón',
  CANNOT_SELECT_PLAN_WITH_COUPONS:
    'No puedes seleccionar un plan si tienes cupones aplicados',
  SELECT_PLAN: 'Seleccionar Plan',
  VIEW_PLANS: 'Ver planes',
  REDEEM_CODE: 'Canjear código',
  USE_COUPONS: 'Usar Cupones',
  ENTER_CODES_HERE: 'Ingresa códigos o cupones aquí',
  ENTER_COUPON_CODE_PLACEHOLDER: 'Ingresa tu código de cupón',
  ENTER_COUPON_CODE: 'Ingresa un código de cupón',
  ADD: 'Añadir',
  COUPON_ALREADY_ADDED: 'Este cupón ya fue agregado',
  COUPON_ADDED_SUCCESS: 'Cupón añadido correctamente',
  COUPON_VERIFICATION_ERROR: 'Error al verificar el cupón',
  MULTIPLE_COUPONS_APPLIED: '%d cupones aplicados',
  ONE_COUPON_APPLIED: '1 cupón aplicado',
  INCOMPATIBLE_COUPON_ERROR:
    'El cupón que añadiste no permite combinarlo con otros',
  MAX_APPSUMO_REACHED: 'Has alcanzado el límite de %d cupones AppSumo',
  MAX_CAMPAIGN_REACHED: 'Has alcanzado el límite de %d cupones para %s',
  MAX_APPSUMO_COUPONS_ERROR:
    'Ya has añadido el máximo de cupones AppSumo permitidos (%d).',
  MAX_CAMPAIGN_COUPONS_ERROR:
    'Ya has añadido el máximo de cupones permitidos (%d) para la campaña "%s".',
  EXISTING_NO_COMPATIBILITY_ERROR:
    'El cupón que ya añadiste no permite combinarse con otros cupones.',
  NEW_NO_COMPATIBILITY_ERROR: 'Este cupón no permite combinarse con otros.',
  DIFFERENT_CAMPAIGN_ERROR:
    'Este cupón pertenece a una campaña diferente y no pueden combinarse.',
  CONFIRM_REMOVAL: 'Confirmar eliminación',
  REMOVE_COUPON_CONFIRM: '¿Estás seguro de que deseas eliminar este cupón?',
  YES: 'Sí',
  NO: 'No',
  THIS_CAMPAIGN: 'esta campaña',
  MESSAGES: 'mensajes',
  WITH_QUOTA: 'con cuota',
  DAILY: 'diaria',
  PROMOTION: 'Promoción',
  WITH_INTEGRATIONS: 'Con integraciones',
  UNLIMITED_USERS: 'Usuarios ilimitados',
  NO_ADDITIONAL_USERS: 'Sin usuarios adicionales',
  ADDITIONAL_USER: 'usuario adicional',
  ADDITIONAL_USERS: 'usuarios adicionales',
  FREE: 'Gratis',
  EVERY: 'cada',
  MONTHS: 'meses',
  YEARS: 'años',
  PLUS_TAXES: '+ impuestos',
  PROMO_CODE_APPLIED: 'Código promocional aplicado',
  HAVE_COUPON_QUESTION: '¿Tienes un cupón o código de descuento?',
  NO_COUPONS_MESSAGE:
    'Si no tienes cupones o códigos, puedes seleccionar un plan',
  // APPSUMO_BENEFITS: 'Beneficios AppSumo',
  COUPON: 'cupón',
  COUPONS: 'cupones',
  APPLIED: 'aplicado',
  APPLIED_PLURAL: 'aplicados',
  APPSUMO_LIFETIME_DEAL:
    'AppSumo Lifetime Deal con mensajes diarios. Acceso de por vida con un solo pago.',
  DAILY_QUOTA: 'Cuota de envíos diarios:',
  ACCESS_TO_INTEGRATIONS: 'Acceso a integraciones con otras plataformas',
  UNLIMITED_USERS_FOR_COMPANY: 'Usuarios ilimitados para tu empresa',
  ADDITIONAL_USERS_COUNT: '%d usuario%s adicional%s',
  S: 's',
  ES: 'es',
  MAX_COUPONS: 'Cupones máximos acumulables:',
  LIFETIME_PLAN: 'Plan lifetime - ¡un solo pago, para siempre!',
  TERMS_AGREEMENT: 'Al crear la cuenta estás aceptando los',
  TERMS_AND_CONDITIONS: 'términos y condiciones',
  AND: 'y la',
  CREATE_ACCOUNT: 'Crear cuenta',
  ALREADY_HAVE_ACCOUNT: '¿Ya tienes una cuenta?',
  INCOMPATIBLE_COUPONS: 'Cupones incompatibles',
  CLEAR_EXISTING_COUPONS: 'Borrar cupones existentes',
  CLEAR_COUPONS_FIRST: 'Si deseas usarlo, debes eliminar los otros primero.',
  SIGNUP_SUCCESS: 'Registro exitoso!',
  SIGNUP_ERROR: 'Error al registrar: ',

  //Pricing
  PRICING_TITLE: 'Planes y Precios',
  PRICING_SUBTITLE: 'Elije el plan perfecto para tus necesidades',
  PRICING_DEVELOPMENT:
    'Si necesitas integrar ENTi con otro software, puedes contratar nuestro servicio de desarrollo.',
  MOST_POPULAR: 'Más Popular',
  START_NOW: 'Comenzar Ahora',
  YEAR: 'año',
  MONTH: 'mes',
  MONTHLY: 'Mensual',
  ANNUAL: 'Anual',
  TWO_MONTHS_FREE: '¡2 meses gratis!',

  BASIC_PLAN: 'Plan Básico',
  BASIC_FEATURE_1: '1 usuario',
  BASIC_FEATURE_2: '10 mensajes diarios',
  BASIC_FEATURE_3: 'Tracking en tiempo real',

  PRO_PLAN: 'Plan Profesional',
  PRO_FEATURE_1: '3 usuarios',
  PRO_FEATURE_2: '50 mensajes diarios',
  PRO_FEATURE_3: 'Tracking en tiempo real',
  PRO_FEATURE_4: 'API de envíos',
  PRO_FEATURE_5: 'Integraciones',
  PRO_FEATURE_6: 'Soporte por Email',
  PRO_FEATURE_7: '10% dcto. en desarrollo de integraciones',

  ENTERPRISE_PLAN: 'Plan Empresarial',
  ENTERPRISE_FEATURE_1: 'Usuarios ilimitados',
  ENTERPRISE_FEATURE_2: '300 mensajes diarios',
  ENTERPRISE_FEATURE_3: 'Real-time tracking',
  ENTERPRISE_FEATURE_4: 'API de envíos',
  ENTERPRISE_FEATURE_5: 'Integraciones',
  ENTERPRISE_FEATURE_6: 'Soporte por Email',
  ENTERPRISE_FEATURE_7: '10% dcto. en desarrollo de integraciones',
};

export default dictionary;
