'use client';
import { signIn } from '@/components/loginFlow';
import { Input, Button } from 'antd';
import { resetPass } from './loginBackend';
import { useEffect, useRef, useState } from 'react';
import { useEntiContext } from '@/services/context';
import i8n from '@/services/lang/i8n';
import Link from 'next/link';
import {
  ArrowRightOutlined,
  UserAddOutlined,
  LoginOutlined,
} from '@ant-design/icons';

export default function LoginForm({ params }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [forceLanguage, setForceLanguage] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showAccountBenefits, setShowAccountBenefits] = useState(true);
  const emailInputRef = useRef(null);
  const { lang } = useEntiContext();
  const __ = i8n(lang, forceLanguage);
  const [activeTab, setActiveTab] = useState('login');

  useEffect(() => {
    setForceLanguage(true);
  }, []);

  async function handleResetPassword() {
    const email = document.querySelector('input[name="email"]').value || '';
    const resetEmail = prompt(__('LOGIN_FORGOT_MODAL_INSTRUCTIONS'), email);
    if (!resetEmail) return;
    resetPass(resetEmail);
    alert('😀👍\n\n' + __('LOGIN_FORGOT_MODAL_FEEDBACK'));
  }

  const logIn = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append(
      'email',
      document.querySelector('input[name="email"]').value,
    );
    formData.append(
      'password',
      document.querySelector('input[name="password"]').value,
    );
    const login = await signIn(formData);
    if (login.error) {
      setError(true);
    } else {
      setError(false);
      location.replace('/envios');
    }
    setIsLoading(false);
  };

  const handleLoginClick = () => {
    setActiveTab('login');
    setShowLoginForm(true);
    setShowAccountBenefits(false);
    setTimeout(() => {
      emailInputRef.current?.focus();
    }, 100);
  };

  return (
    <div className="bg-white w-full md:w-1/2 text-slate-700 rounded-xl shadow-lg drop-shadow-lg shadow-black overflow-hidden">
      <div className="flex flex-col justify-center p-6 md:p-10 text-foreground">
        <div className="text-center">
          <Link href="/">
            <img
              src="/logo-enti.png"
              width="200"
              alt="ENTi"
              className="justify-center"
            />
          </Link>
        </div>

        {/* Opciones de cuenta en la parte superior */}
        <div className="grid grid-cols-2 gap-3 mt-16 mb-8">
          <Link
            href="/signup"
            className="
              flex items-center justify-center gap-2 py-3 px-4 no-underline text-white
              xbg-gradient-to-br from-purple-500 to-orange-600 
              bg-green-500
              rounded-lg font-semibold transition-all duration-200 hover:shadow-md
              hover:translate-y-[-2px] group
            "
          >
            <UserAddOutlined />
            <span>{__('SIGN_UP_NOW')}</span>
            <ArrowRightOutlined className="opacity-0 -translate-x-2 group-hover:opacity-100 group-hover:translate-x-0 transition-all duration-300" />
          </Link>

          <button
            onClick={handleLoginClick}
            className="
              flex items-center justify-center gap-2 py-3 px-4 cursor-pointer
              bg-white border-2 !border-purple-300 text-purple-600 border-solid
              rounded-lg font-semibold transition-all hover:border-purple-500
            "
          >
            <LoginOutlined />
            <span>{__('LOGIN_SIGN_IN')}</span>
          </button>
        </div>

        {/* Notificación para usuarios sin cuenta - ahora solo se muestra si showAccountBenefits es true */}
        {/* {showAccountBenefits && (
          <div className="bg-purple-50 border-l-4 border-purple-500 p-4 mb-6 rounded shadow-sm text-center">
            <p className="text-purple-700">{__('ACCOUNT_BENEFITS')}</p>
          </div>
        )} */}

        {showLoginForm && (
          <>
            {/* <h1 className="text-2xl font-medium text-center mb-6">
              {__('LOGIN_SIGN_IN')}
            </h1> */}

            <div className="flex flex-col gap-2 [&>input]:mb-3">
              <p htmlFor="email">Email</p>
              <Input
                ref={emailInputRef}
                name="email"
                placeholder="usuario@enti.ee"
                required
              />
              <div className="flex justify-between items-center">
                <p htmlFor="password">{__('LOGIN_PASSWORD')}</p>
              </div>
              <Input
                type="password"
                name="password"
                onKeyDown={(e) => e.keyCode === 13 && logIn()}
                placeholder="••••••••"
                required
              />
              {error && (
                <p className="text-red-500 text-sm">{__('LOGIN_INVALID')}</p>
              )}
              <Button onClick={handleResetPassword} type="link">
                {__('LOGIN_FORGOT')}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={logIn}
                required
                loading={isLoading}
              >
                {__('LOGIN_SIGN_IN_2')}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
