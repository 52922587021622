'use client';
import { Input, Button } from 'antd';
import { useEffect, useState } from 'react';
import { createClient } from '@/services/supabase/client';
import { useRouter } from 'next/navigation';
import { useEntiContext } from '@/services/context';
import i8n from '@/services/lang/i8n';
import Link from 'next/link';

export default function LoginForm({ params: { code } }) {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordWeak, setPasswordWeak] = useState(false);
  const [forceLanguage, setForceLanguage] = useState(false);
  const { lang } = useEntiContext();
  const __ = i8n(lang, forceLanguage);

  async function changePass(password) {
    const supabase = createClient();
    try {
      await supabase.auth.exchangeCodeForSession(code);
      const { data, error } = await supabase.auth.updateUser({
        password,
      });
      console.log(`CAMBIO?`, data, error);
      router.replace(__('LINK_ENVIOS'));
      return { status: true };
    } catch (e) {
      return { status: false, e };
    }
  }

  async function handleSetPass() {
    setIsLoading(true);
    const pass = document.querySelector('input[name="password"]').value || '';
    await changePass(pass);
    setIsLoading(false);
  }

  return (
    <div className="bg-white w-1/2 text-slate-700 rounded-xl shadow-lg drop-shadow-lg shadow-black">
      <div className="flex flex-col justify-center p-10 text-foreground">
        <div className="text-center">
          <Link href="/">
            <img
              src="/logo-enti.png"
              width="200"
              alt={__('ENTI_LOGO_ALT')}
              className="justify-center"
            />
          </Link>
        </div>
        <h1 className="text-2xl font-medium text-center">
          {__('LOGIN_RESET_TITLE')}
        </h1>
        <div className="flex flex-col gap-2 [&>input]:mb-3 mt-8">
          <div className="flex justify-between items-center">
            <p htmlFor="password">{__('LOGIN_RESET_NEW_PASSWORD')}</p>
          </div>
          <Input
            type="password"
            name="password"
            onKeyDown={(e) => e.keyCode === 13 && handleSetPass()}
            onChange={(e) => {
              if (e.target.value.length < 8) {
                setPasswordWeak(true);
              } else {
                setPasswordWeak(false);
              }
            }}
            placeholder={__('LOGIN_RESET_PASSWORD_PLACEHOLDER')}
            required
          />
          {passwordWeak && (
            <p className="text-red-500">{__('LOGIN_RESET_PASSWORD_WEAK')}</p>
          )}
          <Button
            type="primary"
            onClick={handleSetPass}
            disabled={isLoading || passwordWeak}
            required
            loading={isLoading}
          >
            {__('LOGIN_RESET_BUTTON')}
          </Button>
        </div>
      </div>
    </div>
  );
}
