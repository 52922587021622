// como usar:
//   import i8n from '@/services/lang/i8n';
// y dentro del componente:
//   const { lang } = useEntiContext();
//   const __ = i8n(lang);
'use client'
import { useLanguage } from '@/services/serverLanguageContext'
import es from './es'
import en from './en'

const dictionaries = { en, es }

export default function i8n(lang, force = true) {
  const { locale } = useLanguage()

  const dictionary = force ? dictionaries[lang] : dictionaries[locale]

  return function __(string) {
    return dictionary ? dictionary[string] || string : '';
  }
}